.searchBox {
    border-radius: 30px;
}

.doc-detail {
    --borderColor:#273896;
    display: flex;
    align-items: center;

    svg {
        font-size: 32px;
        margin-right: 5px;
        path {
            stroke: var(--borderColor);
        }
    }

    span {
        width: 170px;
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .type {
            font-size: 12px;
        }
    }
}

.downloadBtn {
    width: 100px;
    height: 10px;
    float: right;
    font-size: 12px;
    border: none;
    background-color: #F8F7FB;
}