
// .ant-modal-mask {
//     background-color: rgb(0 0 0 / 0%);
// }
// .document-upload {
    .model-document-upload {
        top: auto !important;
        width: 400px !important;
        bottom: 0 !important;
        right: 25px !important;
        position: absolute !important;
        border-radius: 8px;
        .ant-modal-mask {
            
        }
        .ant-modal-body {
            padding: 0px;
        }
        .ant-modal-content {
            border-radius: 8px !important;
        }
        & .header {
            padding: 14px;
            font-size: 1rem;
            background-color: black;
            color: white;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .ant-modal-close {
            color: white;
        }
        & .header-title {
            font-size: 18px;
        }
        .ant-col {
            padding-block: 7px;
        }
        .document-content {
            padding: 0px 14px;
        }
        .doc-cols {
            display: flex;
            align-items: center;
        }
        .document-name {
            font-weight: 600;
            line-break: anywhere;
        }
        .document-size {
            font-weight: 400;
        }
        .loading-img {
            animation: spin 1s linear infinite;
        }
        @keyframes ckw {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
// }

.doc-panel-header {
    @media only screen and (max-width: 768px) {
        .ant-collapse-header {
            padding-left: 7px !important;
        }
    }
}

.ant-input[disabled] {
    color: black ;
  }
  .ant-select-selection-item {
    color: black;
  }
  .save-btn {
    width: 100px !important;
  }

  .doc-mobile-items-header {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        display: grid;
        .doc-category-tags {
            margin: 0px;
            margin-right: 10px;
        }
    }
  }

  .doc-mobile-items {
      padding: 10px;
      border-bottom: 1px solid lightgray;
    .doc-mobile-item-main {
        display: flex;
        margin-bottom: 5px;
    }
    .doc-mobile-item {
        flex: 1;
        display: grid;
        .doc-title {
            font-weight: 300;
        }
    }
  }