.disbursed-lenders-header {
    margin-bottom: 10px;

    .disbursed-heading {
        font-size: 12px;
    }
}

.selected-lender-row {
    align-items: center;

    .disbursed-row {
        h5 {
            font-size: 13px;
            font-weight: normal;
        }
    }
}

.btn-add {
    border: none;
    background: none;
    padding: 0px;

    img {
        padding: 4px;
        margin-bottom: 10px;
        border: solid 1px #C71C8E;
        border-radius: 20px;
    }
}

.inline-label {
    .ant-row {
        display: block;

        .ant-col {
            width: 100%;

            label {
                display: block;
                text-align: left;
                height: auto;
            }
        }
    }
}

.payout-collapse {
    background-color: #fff;
    border: solid 1px #f6f6f6;
    border-radius: 6px;
    box-shadow: 0px 1px 5px #e1e1e1;

    .ant-collapse-item {
        border-bottom: none;

        .ant-collapse-header {
            width: 100%;
            min-height: 44px;

            // padding: 4px 8px;
            // .ant-collapse-expand-icon{
            //     display: none;
            // }
            .ant-collapse-header-text {
                width: 100%;

                h4 {
                    font-size: 14px;
                    line-height: normal;
                    margin-bottom: 0px;
                }

                h6 {
                    font-size: 13px;
                    margin-bottom: 0px;
                }

                .api-intergrated-text {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    color: green;
                    line-height: 15px;
                }
            }
        }

        .ant-collapse-content-box {
            padding: 0px;
            padding-bottom: 16px;
        }
    }
}

.bg-title {
    padding-block: 10px;
    padding-inline: 16px;
    background: rgba(217, 217, 217, 0.2);
}

.disbursal-form {
    padding: 5px;
    display: flex;
    align-items: center;
}
.ant-collapse-content-box {
    padding: 0px;
    padding-bottom: 100px;
    height: 350px !important;
    overflow-y: auto;
    .disbursal-item {
      margin-bottom: 8px;
      border-bottom: 1px solid lightgray;
    }
  }

  @media screen and (max-width: 1000px) {
    .disbursed-detail-div{
        margin-left: 13px !important;
    }
  }