.ant-form-item-has-error{
    padding-bottom: 12px !important;
}

.field-border {
    border-color: rgba(0, 0, 0, 0.19) !important;
}
/*
.field-bg {
    padding: 5px 10px;
    background: #FFFFFF;
    box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
    border-radius: 12px;
    border: solid 1px #f7f7f7;
    margin-bottom: 10px;
    .ant-form-item-label{
        font-weight: 400;
        padding: 0px !important;
        color: rgba(0,0,0,.65);
    }
    .input.ant-input,
    .ant-input-number-input {
        padding-block: 0px !important;
        height: auto !important;
        padding-left: 0px;
    }
    .ant-select-selector,
    .custom-input-phone,
    .custom-input-number,
    .custom-input{
        border-radius: 0px;
        padding-right: 8px;
        padding-left: 8px;
        padding-block: 2px !important;
        border: none !important;
        font-size: 16px;    
        margin-block: 4px;    
    }
    .ant-select-selector {
        height: 30px !important;
        padding-block: 0px !important;
        padding-left: 8px !important;
        // margin-top: -6px;
    }
}*/
.field-bg{
    padding: 8px 10px;
    background: #FFFFFF;
    box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
    border-radius: 12px;
    border: solid 1px #f7f7f7;
    margin-bottom: 10px;
    .ant-select-selector,
    .custom-input-phone,
    .custom-input-number,
    .custom-input{
        border-radius: 0px;
        padding: 8px;
        padding-left: 0px;
        border: none !important;
        font-size: 16px;  
        
      }
}

.custom-input-phone{
    border-radius: 30px;
    padding: 5px;
    border: solid 1px #f1f1f1;
    .ant-input-wrapper{
        .ant-input-group-addon{
            border: none;
            width: 60px !important;
            .ant-select{
                background: #fff;
                width: 60px !important;
            }
        }
    }
    input{
        border: none;
        outline: none !important;
        box-shadow: none !important;
    }
}
