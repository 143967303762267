.mobile-view-container {
    border-radius: 20px;
    height: auto;
    width: 100%;
}

.mobile-view-list {

    height: auto;
    width: 100%;
    padding: 10px;
}

.mobile-upload-container {

    margin-top: 20px;
    border-radius: 20px;
    height: auto;
    width: 100%;
}

.mobile-upload-document {
    padding: 30px;
}

.upload-area {
    // .ant-radio .ant-radio-checked {
    //     color: #C71C8E !important;
    // }
}

.custom-checkable {
    padding: 4px 10px;
    // border: 1px solid #C71C8E;
    border-radius: 40px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    margin-left: 0px;
    margin-top: 5px;
}

.types-document {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.doc-list-mobile {
    display: table;
    width: 90%;
    padding-bottom: 30px;
    height: 215px;
    overflow: scroll;
    margin-top: 20px;

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px #ededed;
            margin-bottom: 10px;
            padding-bottom: 10px;

            .doc-details {
                display: flex;
                align-items: center;

                svg {
                    font-size: 32px;
                    color: #273896;
                    margin-right: 5px;
                }

                span {
                    width: 170px;
                    font-size: 13px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    margin-left: 5px;

                    .type {
                        font-size: 12px;
                    }
                }
            }

            .doc-action {
                span {}

                span.remove {
                    color: #E12121;
                    cursor: pointer;

                    svg {
                        margin-right: 8px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }

        li:last-child {
            border-bottom: none;
        }
    }
}

.upload-wrapper {
    .ant-upload-select {
        width: 100%;
        height: 100px;
        margin: 14px 0px;

        img {
            margin-right: 10px;
            height: 30px;
            width: 25px;
        }

        span {
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #505050;
            font-size: 16px;
            border: dashed 1.9px #505050 !important;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .ant-upload-list {
        padding-bottom: 15px;

        .ant-upload-list-item {
            margin-bottom: 10px;
        }
    }
}


@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    [className="col-1"] {
        width: 100%;
    }
}

.lead-detail-box-mobile {
    height: 100%;

    .heading {
        display: flex;
        border-bottom: solid 1px #ccc;
        padding: 6px 15px;
        align-items: baseline;
        justify-content: space-between;
        min-height: 46px;
        align-items: center;

        h5 {
            font-weight: 900;
            font-size: 17px;
            line-height: 22px;
            color: #000;
            margin-bottom: 0px;
        }

        button {
            font-size: 12px;
        }
    }

    .custom-row {
        justify-content: space-between;
        margin-bottom: 10px;

        .gap {
            gap: 4px !important;
        }
    }

    .title {
        color: #9f9f9f;
        font-size: 13px;
    }

    .lead-details {
        padding: 10px 15px;
        border-bottom: solid 1px #e9e9e9;

        .source {
            font-weight: 400;
            font-size: 12px;
            color: #6a6767;
        }

        h5 {
            margin-bottom: 2px;
        }
    }

    .details-item {
        padding: 15px;

        textarea {
            width: 100%;
            border-radius: 8px;
        }

        .btn-space {
            display: flex;
            margin-top: 10px;
            justify-content: end;
            clear: both;
        }
    }
}

// .body{
//     background-color: #E5E5E5;
// }

.lead-deatils-text {
    // font-size: "10px";
}

.lead-deatils-text-highlight{
    font-size: "30px";
}