
.fa-times-circle-o::before{
    content: url('../../../assets/images/delete.svg');
}
.fa-times-circle-o:hover{
    background-color: white;
}

.formio-button-remove-row{
    background-color: white;
    border: 0px;
}
.formio-button-remove-row:hover{
    background-color: white;
}
.formio-button-remove-row:active:hover{
    background-color: white;
    border: 0px;
}
.text-muted::before{
    // content:url("../../../../assets/images/add.svg");
    content: '+';
    color: black;
}
.fa-calendar:before{
    content:url("../../../assets/images/calender.svg");
}
.input-group-prepend {
    & .input-group-text{
        font-size:1rem
    }
}
.input-group-text{
    font-size:0.1rem
}
.fa-plus:before{
    // content:url("../../../../assets/images/add.svg");
    content: '+';
    color: white;
}
.fa-refresh:before{
    content: '↻';
    display: none;
}