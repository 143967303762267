.hide-selection .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: not-allowed;
    display: none !important;
}

.payout-tabs {
    margin-bottom: 30px;
    background-color: #E8EBF5;
    height: 40px;
    border-radius: 10px;
}

.ant-tag-checkable-checked.custom-tag {
    background-color: #FFFFFF;
    color: black;
    height: 40px;
    border-radius: 10px;
    line-height: 3;
    margin-left: 0px !important;
}

.agreement-btn {
    width: 120px;
    height: 35px;
    border-radius: 7px;
}

.hide-columns {
    display: none !important;
}

.ant-table-selection-col {
    width: 0px !important;
}