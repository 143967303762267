.site-card-wrapper {
  padding: 30px;
  background: red;
}

.custom-button-checkable {
  padding: 6px 30px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  margin-left: 0px;
  margin-top: 5px;
  &:hover,
  &:focus{
    color: #000 !important;
    background: none ;
  }
}

.custom-button-checkable.ant-tag-checkable-checked {
  --bgColor:#273896;
  background: var(--bgColor);
  &:hover,
  &:focus{
    color: #fff !important;
  }
}

.notification-img{
  --appPrimary: #273896;
  padding-left: 4px;
  margin: auto;

  svg {
    circle,
    path {
      fill:var(--appPrimary);  
    }
 }
}

.read-notification {
  margin-top: 1%;
  .ant-card-meta-title {
    font-weight: 300;
  }
  .ant-card-meta-description {
    font-weight: 300;
    margin-top: -8px;
  }
}

.unread-notification {
  margin-top: 1%;
  .ant-card-meta-description {
    font-weight: 300;
    margin-top: -8px; 
  }
}