@import '../../shared/style/Variables.scss';

.modal-Add-extra {
  .ant-modal-body {
    padding-right: 0px !important;
  }
}

#basic {
  max-height: 65vh;
  overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
}

.badge-green {
  height: 15px;
  width: 15px;
  background-color: green;
  position: absolute;
  top: -3px;
  right: -2px;
  border-radius: inherit;
}

.assigned-lender-list {
  margin-top: 20px;

  .assigned-list {
    padding: 8px 10px;
    background: #ffffff;
    box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
    border-radius: 6px;
    border: solid 1px #f7f7f7;
    margin-bottom: 10px;
  }

  .ant-radio-group {
    width: 100%;
  }

  .ant-space-vertical {
    width: 100%;
  }

  .lender-checkbox {
    display: flex;
    align-items: center;

    .ant-checkbox-inner {
      width: 21px;
      height: 21px;
      border-radius: 10px;

      &::after {
        left: 30.5%;
      }
    }

    .ant-checkbox {
      margin-bottom: 5px;
    }
  }

  .checkbox-text {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #5c5b5b;
    line-height: 15px;
  }

  .checkbox-text.small {
    font-size: 10px;
  }

  .checkbox-text-div {
    display: flex;
    justify-content: space-between;
    padding-block: 5px;
  }

  .api-intergrated-text {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: green;
    line-height: 15px;
  }

  .ant-checkbox+span {
    width: 100%;
  }
}

.left-nav {
  header {
    .ant-row {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

.btn-box {
  margin-bottom: 10px !important;

  .ant-radio-group {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ant-radio-group::-webkit-scrollbar {
    display: none;
  }

  .ant-radio-button-wrapper {
    margin-right: 10px;
    border-radius: 30px;
    margin-bottom: 10px;
    border-left-width: 1.02px;
    cursor: pointer;

    &::before {
      display: none;
    }
  }
}

.comment-box {
  margin-top: 30px;
  width: 100%;

  textarea {
    height: 100px;
  }
}

.next-btn {
  width: 100%;
  margin-top: 20px;
  height: 40px;
  border-radius: 20px;
}

.action-button {
  button {
    border: none;
    line-height: 26px;

    svg {
      font-size: 11px !important;
    }
  }

  .blue {
    background: #273896;
  }

  .green {
    background: #20a74e;
  }

  .pink {
    background: #c71c8e;
  }

  .red {
    background: #ff0000;
    color: white;
  }
}

.refresh-button {
  height: 45px;
  width: auto;
  border-radius: 12px;
  // float: right;

  svg {
    font-size: 18px;
  }
}

.lead-detail-box {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  // height: 682px;
  height: 100%;

  .heading {
    display: flex;
    border-bottom: solid 1px #ccc;
    padding: 6px 15px;
    align-items: baseline;
    justify-content: space-between;
    min-height: 46px;
    align-items: center;

    h5 {
      font-weight: 900;
      font-size: 17px;
      line-height: 22px;
      color: #000;
      margin-bottom: 0px;
    }

    button {
      font-size: 12px;
    }
  }

  .custom-row {
    justify-content: space-between;
    margin-bottom: 10px;

    .gap {
      gap: 4px !important;
    }
  }

  .title {
    color: #9f9f9f;
    font-size: 13px;
  }

  .border-top {
      border-bottom: solid 1px #e9e9e9;
  }

  .lead-details {
    padding: 10px 15px;
    border-bottom: solid 1px #e9e9e9;

    .source {
      font-weight: 400;
      font-size: 12px;
      color: #6a6767;
    }

    h5 {
      margin-bottom: 2px;
    }
  }

  .details-item {
    padding: 15px;

    textarea {
      width: 100%;
      border-radius: 8px;
    }

    .btn-space {
      display: flex;
      margin-top: 10px;
      justify-content: end;
      clear: both;
    }
  }
}
.timeline::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
  display: block;
}

.timeline::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: lightgray;
}

.timeline {
  padding: 27px 15px;
  height: auto;
  max-height: 690px;
  overflow: hidden;
  overflow-y: auto;
  //-ms-overflow-style: none;
  /* Internet Explorer 10+ */
  //scrollbar-width: none;

  /* Firefox */
  .timeline-box {
    --color: #c71c8e;
    h4 {
      margin-bottom: 0px;
      color: var(--color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;

      .date {
        font-weight: 400;
        font-size: 11px;
        color: #a4a4a4;
      }
    }

    h5 {
      margin: 0px;
    }

    .title-date {
      span.date {
        font-weight: 400;
        font-size: 11px;
        color: #a4a4a4;
      }
    }
    .ant-timeline-item-head{
      background-color:var(--color);
  }
  }
}


.close-case-box {
  padding: 10px 0px;
}

.menu-list {
  .ant-dropdown-trigger {
    cursor: pointer;
  }
}

ul.menu-list {
  width: 200px;
  border-radius: 8px;
}


.top-view {
  height: 60px;
  border-radius: 8px;
  padding-block: 5px;
  padding-inline: 10px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  font-weight: 300;
}

.home-black-img{
  height: 25px;
  width: 25px;
}

.assign-to-sales {
  display: flex;
  padding-block: 10px;
  padding-inline: 15px;
  gap: 8px;
  width:110%;
  background-color: rgba(217, 217, 217, 0.2);
  margin-inline: -15px;
  margin-bottom: 5px;
  .ant-select{
    min-width: 50%;
    width: auto;
  }
}
.ant-list-pagination{
  display: flex !important;
  justify-content: end !important;
}
.leadlist-title{
  color: $mobile-view-title;
}
.ant-list-item{
  padding: 0px;
}
.title-space{
  padding-top: 15px;
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
  background-color: #e9e9e9;
 // border-color: #2e43b2;
  color: black;
}

.dynamic-tabs {
  --bgColor: #273896;
    
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--bgColor) !important;
      }
    }
  
    .ant-tabs-ink-bar {
      background-color: var(--bgColor);
    }
}
