.doc-collapse {
  margin-top: 20px;
  background-color: #fff;
  border: solid 1px #bfc4e1;
  border-radius: 6px;
  .ant-collapse-item {
    border-bottom: none;
    .ant-collapse-header {
      width: 100%;
      min-height: 44px;
      .ant-collapse-header-text {
        width: 100%;
      }
    }
    .ant-collapse-content-box {
      padding: 0px;
      
      .ant-row {
        padding-block: 6px;
        border-bottom: 1px solid #bfc4e1;
        .ant-form-item-row {
          border: none;
          // border-radius: 11px;
          .custom-input{
          margin: 5px;
          padding: 5px;
          border: 1px solid #bfc4e1;
          border-radius: 11px;
          }
        }
        .action-btns {
          margin-right: 5px;
          overflow: hidden;
          .ant-btn {
            background-color: transparent;
          }
        }
        .ant-btn .button-submit {
          color: #ffffff;
          background-color: #273896;
          border-color: #273896;
        }
        .custom-select .ant-select-selector {
          border-radius: 11px !important;
        }
        // .ant-btn {
        //   background-color: transparent;
        // }
      }
      .error {
        background-color: rgba(225, 33, 33, 0.14);
      }
    }
  }
}
.ant-popover.ant-popconfirm.ant-popover-placement-topRight.topRight-popup {
  left: 50% !important;
  right: 120px !important;
}
.doc-checkbox {
  justify-content: center;
  display: flex;
  flex-wrap: inherit;
  align-content: center;
}
.ant-select-selection-item {
   & .downloadBtn {
    display: none;
  }
}