.upload-document-wrapper{
    h5{
        font-size: 16px;
        // margin-bottom: 20px;
    }
    .upload-btn{
        width: 206px;
        margin: auto;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        border-color:#fff !important;
        svg{
            margin-right: 10px;
        }
        &:hover{
            border-color:#fff;
        }
    }
    
    .doc-list{
        display: table;
        width: 100%;
        padding-bottom: 30px;
        ul{
            // list-style: none;
            padding: 0px;
            margin-left: 5%;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 1px #ededed;
                margin-bottom: 10px;
                padding-bottom: 10px;
                .doc-details{
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 32px;
                        color: #273896;
                        margin-right: 5px;
                    }
                    span{
                        width: 170px;
                        font-size: 13px;
                        text-overflow: ellipsis;
                        overflow: hidden; 
                        white-space: nowrap;
                        .type{
                            font-size: 12px;
                        }
                    }
                }
                .doc-action{
                   
                    span.remove{
                        color: #E12121;
                        cursor: pointer;
                        svg{
                            margin-right: 8px;
                            position: relative;
                            top: 3px;
                        }
                    }
                }
            }
            li:last-child{
                border-bottom: none;
            }
        }
    }
}

.doc-name{
    display: flex;
    span{
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
    }
}

.required-document-box {
    height: 90%;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: grid;
    align-content: start;
    .doc-title {
        margin-top: 15px;
        padding-bottom: 12px;
        margin-bottom: 15px;
        padding-left: 20px;
        border-bottom: 2px solid #bfc4e1;
        font-size: 20px;
        display: grid;
    }
    .doc-list{
        display: table;
        width: 100%;
        padding-bottom: 30px;
        ul{
            // list-style: none;
            padding: 0px;
            margin-left: 5%;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 1px #ededed;
                margin-bottom: 10px;
                padding-bottom: 10px;
                .doc-details{
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 32px;
                        color: #273896;
                        margin-right: 5px;
                    }
                    span{
                        width: 200px;
                        font-size: 13px;
                        text-overflow: ellipsis;
                        overflow: hidden; 
                        white-space: nowrap;
                        .type{
                            font-size: 12px;
                        }
                    }
                }
                .doc-action{
                   
                    span.remove{
                        color: #E12121;
                        cursor: pointer;
                        svg{
                            margin-right: 8px;
                            position: relative;
                            top: 3px;
                        }
                    }
                }
            }
            li:last-child{
                border-bottom: none;
            }
        }
    }
}

.documnet-list {
    // background: #FFA800;
    // height: max-content;
    // width: 90%;
    display: flex;
    background: rgba(255, 168, 0, 0.13);
    border: 2px solid rgba(255, 168, 0, 0.25);
    border-radius: 20px;
    // position: absolute;
}

.upload-document-modal{
    padding-bottom: 20px;
}
.lead-documnet-display-outer{
    margin-left: 50%;
}
.custom-select{
    width: 100%;
}
custom-checkable.ant-tag-checkable-checked {
    background: #14237b;
}
.custom-checkable-doc{
    font-size: 10px;
}

.upload-btn-submit{
    width: 100%;
    margin-top: 20px;
    border-radius: 30px;
    height: 41px;
}

.upload-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    button{
        margin: 0px 5px;
    }
}
.upload-wrapper{
    
    .ant-upload-select-text{
        width: 100%;
        height: 76px;
        margin: 14px 0px;
        svg{
            margin-right: 10px;
        }
        span{
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            border: dotted 1px #ccc;
            cursor: pointer;
        }
    }
    .ant-upload-list{
        padding-bottom: 15px;
        .ant-upload-list-item{
            margin-bottom: 10px;
        }
    }
}

.doc-name{
    display: flex;
    span{
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
    }
}
.loader-view-flowable {
    position: absolute;
    inset: 0;
    z-index: 10;
    background-color: black;
    opacity: 0.7;
    display: grid;
    align-content: center;
    .ant-spin {
      .ant-spin-text {
        font-size: 20px;
        margin-top: 10px;
      }
    }
  }

  .replace-doc {
    padding-block: 20px;
    .search {
        display: flex;
        justify-content: center;
        .ant-select {
            width: calc(100%);
        }
        .width-100 {
            width: 100%;
        }
    }
  }

  .rename-doc {
    padding-block: 20px;
    .buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .ant-btn {
            width: 48%;
            margin-top: 20px;
            border-radius: 8px;
            height: 41px;
        }
    }
  }

.document-status-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #eaeaea;
    padding-block: 10px;
    span {
        font-weight: 300;
    }
}

.document-status-list {
    .success {
        color: #20A74E;
    }
    .failed {
        color: #E12121;
    }
}
.doc-category-tags {
    --bgColor: #3991F6;
    --color: #fff;
    display: inline-block;
    width: 118px !important;
    height: 23px !important;
    background-color: var(--bgColor);
    color: var(--color);
    font-family: Avenir LT Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 141%; /* 19.74px */
    letter-spacing: 0.2px;
    margin: 0px 5px;
    border-radius: 3px;
    float: right;
    text-align: center;
    padding: 2px;
}