@import "../../../shared/style/Variables.scss";

.fa-question-circle {
  content: url('../../../assets/images/info_black.svg');
  height: 18px;
  margin-bottom: -3px;
}

.col-border-right-3 {
  border-right: 3px solid rgb(235, 234, 238);
}

.border-top-3 {
  border-top: 3px solid rgb(235, 234, 238);
}

.col-border-bottom-3 {
  border-bottom: 3px solid rgb(235, 234, 238);
}

.content-wrapper-new {
  padding: 52px 15px !important;
  padding-top: 20px !important;
  padding-right: 0px !important;
  margin-left: -3% !important;
}

.manage-form {
  background-color: white;
  border-radius: 5px;
  margin-left: -1.9em;
  position: fixed;
  padding: 20px;
  display: contents;
}

.name-logo {
  --bgColor: #c71c8e;
  width: 40px;
  height: 40px;
  font-weight: normal;
  font-size: 20px;
  background-color: var(--bgColor) !important;
  border-radius: 80%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.logo-header {
  display: flex;
  justify-content: center;
  color: #fff;
}

.activity {
  margin-left: 1.1rem;
  margin-top: 1.1rem;
  color: gray !important;
}

.user-details {
  font-size: smaller;
  font-weight: 400;
}

.whatsapp-icons {
  border: none;
}

.details-icons {
  float: right;
  color: black;
  border: none;
}

.borrower-icon {
  float: right;
  color: black;
  border: none;
  margin-top: -9px;
}

.activity-btn {
  color: gray;
  width: 100%;
  display: flex;
  justify-content: left;
  border: none;
  font-weight: 400 !important;
  font-size: 14px;
}

.add-comment {
  height: 100px;
  color: #0000004d;
  cursor: pointer;
}

.btn-side-by-side {
  .fieldset-body {
    display: flex;
    gap: 10px;
    direction: rtl;
    justify-content: end;
  }
}

.action_submit {
  width: 100px;
  margin-top: 10px !important;
  text-align: center;
}

.action_close {
  width: 100px;
  margin-top: 10px;
  background: transparent !important;
  color: #c71c8e !important;
  border-color: #c71c8e !important;

  :hover {
    background: transparent;
    color: #c71c8e;
    border-color: #c71c8e;
  }
}

.formio-component-currency,
.formio-component-textfield,
.formio-component-number,
.formio-component-select,
.formio-component-email {
  width: 100%;
}

.back-btn {
  --btnColor:#4285F4;
  color:var(--btnColor);
  border: 1px solid var(--btnColor) !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-inline: 6px;
  margin-right: 10px;

  &:hover,
  &:focus{
    border : 1px solid var(--btnColor);
    color: var(--btnColor);
  }
  
  svg {
    width: 30px;
    circle,
    path {
      fill:var(--btnColor);  
    }
 }
}

.customer-details {
  display: grid;
  color: rgba(60, 60, 60);

  .item {
    font-size: 12px;
  }
}

.lender-main-tabs {
  --color: #3a47ad;

  .ant-tabs-nav {
    background: #bfbfbf1c;
    border-bottom: 3px solid #fff !important;

    .ant-tabs-tab-active {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 15px;
      border: none;
      cursor: pointer;
      transition: color 0.15s ease-in;
      background-color: #fff;
      border-bottom: 3px solid #fff !important;
      z-index: 2;

      &:hover {
        color: var(--color);
      }
    }
  }

  .ant-tabs-tab-btn:hover {
    color: var(--color) !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color) !important;
  }

  .ant-tabs-tab-btn {
    margin-left: 15px;
  }
}

.lender-sub-tabs {
  --borderColor: #3a47ad;

  .ant-tabs-nav-wrap {
    background: white !important;

    .ant-tabs-tab-active {
      border-bottom: 3px solid var(--borderColor) !important;
      z-index: 2;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--borderColor) !important;
    }
  }

}

.tab-Userdetails {
  background-color: #F5F5F6;
  display: flex;
  justify-content: space-between;

  .publish-btn {
    color: #3a47ad;
    border-color: #3a47ad;
    margin-top: 8px;

    img {
      margin-bottom: 3px;
      margin-right: 5px;
    }
  }

  .userdetails {
    gap: 30px;
    width: 100%;

    p {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
}

.formio-hidden {
  width: 0px !important;
}