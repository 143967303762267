.btn-common {
    width: 100%;
    // margin-top: 2%;
    border-radius: 8px;
    svg{
       margin-left: -5px !important;
       margin: -1px;
    }
}

.svg-icon{
    svg{
        margin-right: 5px !important;
    }
}