.btn-box{
    margin-bottom: 30px;
    .ant-radio-button-wrapper{
        margin-right: 10px;
        border-radius: 30px;
        cursor: pointer;
        &::before{
            display: none;
        }
    }
}

.action-button{
    button{
        border: none;
        line-height: 26px;
        svg{
            font-size: 11px !important;
        }
    }
    .blue{
        background: #273896;
    }
    
    .green{
        background: #20A74E;
    }
    
    .pink{
        background: #C71C8E;
    }
}