.loader-view {
  position: absolute;
  inset: 0;
  z-index: 10;
  background-color: black;
  opacity: 0.7;
  display: grid;
  align-content: center;
  .ant-spin {
    .ant-spin-text {
      font-size: 20px;
      margin-top: 10px;
    }
  }
}
