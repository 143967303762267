@import "../../shared/style/Variables.scss";

.app-header {
  background: $white;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  min-height: 60px;
  max-height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  z-index: 1;

  .header {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    min-height: 46px;
    justify-content: space-between;
    gap: 10px;

    .notification-bell, .header-logo {
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .notification-bell {
      background: $white;
      padding: 9px;
      width: 40px;
      height: 40px;
      cursor: pointer;

      .ant-badge-count {
        min-width: 20px;
        height: 20px;
        margin-top: 6px;
        margin-right: 4px;

        .ant-scroll-number-only {
          font-size: 1px;

          .ant-scroll-number-only-unit {
            font-size: 13px;
          }
        }
      }
    }

    .header-logo {
      width: 35px;
      height: 35px;
      border-radius: 80%;
      font-weight: normal;
      font-size: 20px;
      background-color: $light-gray;
      color: $black;
      border-color: $light-gray;
    }
  }

  .profile-bar {
    display: flex;
  }
}


.custom-app-card {
  border-top-left-radius: 0px !important;
}

.content-box1 {
  margin-inline: 25px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content-box {
  margin: 25px;
}

.mview {
  height: 90px;
}

.sider-menu-icon.collapsed {
  transform: rotate(180deg);
}

.ant-layout-sider-collapsed .sider-icon-collapse {
  transform: rotate(0deg);
}

.sider-icon-collapse {
  --bgColor:#1890ff;
  height: 25px;
  position: absolute;
  right: -17px;
  top: 16px;
  border-radius: 13px;
  z-index: 2;
  transform: rotate(180deg);

    path{
      fill:var(--bgColor) !important;
   }
}

.sider-divider-vertical {
  height: 100%;
  padding: 0;
  margin-inline: 4px;
  background-color: #a9a9a9;
  float: right;
  width: 3px;
}

.ant-layout-sider {
  background-color: $white !important;
  flex: 0 0 225px !important;
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important;

  &.ant-layout-sider-collapsed {
    min-width: 80px !important;
    width: 80px !important;
    flex: 0 0 80px !important;
    background: #f5f5f5 !important;
  }

  .ant-layout-sider-children {
    overflow: auto;
    -ms-overflow-style: none;
    background: $white;
    scrollbar-width: none;
  }

  .ant-layout-sider-children::-webkit-scrollbar {
    display: none;
  }
}

.desktop-layout {
  background-color: #ebecee;
  height: 100vh;

  .left-nav {
    background-color: gray;
    height: 100%;
    width: 250px;
    position: fixed;
  }

  .content-wrapper {
    height: 100vh;
    overflow: auto;

    .body {
      padding: 20px;
    }
  }
}

.content-wrapper::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
  display: block;
}

.content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: $light-gray;
}