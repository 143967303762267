.step-button{
    background: #e8e8e8;
    border: #e8e8e8;
    color: #000;
    width: 100%;
    font-size: 14px;
    padding: 10px 9px;
    height: auto;
    border-radius: 30px;
    outline: none !important;
    span{
        float: left;
    }
    &:hover{
        background: #e8e8e8;
        border: #e8e8e8;
    }
    &:focus{
        background: #e8e8e8;
        border: #e8e8e8;
    }
}

.form-box{
    padding: 20px 0px;
}

.custom-tab{
    --bgColor: #ffd5f1;
    --color: #273896 ;

    margin-top: 30px;
    .ant-tabs-tab:hover{
        color: var(--color) !important;
    }
    .ant-tabs-nav{
        color: var(--color);
        background: var(--bgColor);
        padding: 0px 6px !important;
        border-radius: 11px !important;
        &::before{
            display: none;
        }
        .ant-tabs-nav-wrap{
            .ant-tabs-nav-list{
                display: block;
                width: 100%;
                padding: 6px 0px;
                .ant-tabs-tab{
                    width: 50%;
                    margin: 0 !important;
                    padding: 8px 0px;
                    .ant-tabs-tab-btn{
                        width: 100%;
                        text-align: center;
                    }
                }
                .ant-tabs-ink-bar{
                    display: none;
                }
                .ant-tabs-tab.ant-tabs-tab-active{
                    .ant-tabs-tab-btn{
                       color: #000;
                    }
                    background: #fff;
                  //  color: #000;
                    border-radius: 11px !important;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
                }
            }
        }
        .ant-tabs-nav-operations {
            visibility: hidden;
            width: 0px;
        }
    }
}

.search-box{
    width: 100%;
    box-shadow: 1px 1px 4px #eaeaea;
    border: solid 1px #f1f1f1;
    border-radius: 11px !important;
    background-color: #fff !important;
    .ant-select-selector {
        height: 40px !important;
        border-radius: 11px !important;
        padding: 8px;
        padding-left: 0px;
        border: none !important;
        font-size: 16px; 
    
        input {
          height: 38px !important;
          background: url('../../assets/images//search-icon.png') no-repeat right center !important;
          background-size: 20px 20px !important;
          padding-right: 25px !important;
        }
    
        .ant-select-selection-placeholder {
          line-height: 37px;
        }
    }
    input{
        background-color: #fff !important;
        padding: 10px;
        border-color: #fff !important;
        border-bottom-left-radius: 11px !important;
        border-top-left-radius: 11px !important;
    }
    .ant-input-group-addon{
        border-bottom-right-radius: 11px !important;
        border-top-right-radius: 11px !important;
        button{
            height: 44px;
            background: none;
            border-color: #fff;
            background: #fff;
            border-bottom-right-radius: 11px !important;
            border-top-right-radius: 11px !important;
        }
    }
}

.field-bg{
    padding: 8px 10px;
    background: #FFFFFF;
    box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
    border-radius: 12px;
    border: solid 1px #f7f7f7;
    margin-bottom: 10px;
    .ant-select-selector,
    .custom-input-phone,
    .custom-input-number,
    .custom-input{
        border-radius: 0px;
        padding: 8px;
        padding-left: 0px;
        border: none !important;
        font-size: 16px;  
        
      }
}

.note-text {
    font-size: 14px;
    color: #212529;
}

.img-right {
    margin-right: 5px;
    padding: 5px;
    border: solid 1px #20A74E;
    border-radius: 20px;
}

.col-border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.buttons {
    width: 100%;
    .ant-space-item{
        width: 50%;
        button{
            width: 100%;
            border-radius: 11px;
        }
    }
}

.button-submit {
    width: 150px;
    border-radius: 11px;
}

.assign-list{
    .name{
        font-size: 12px;
        color: #000;
    }
    .city{
        font-size: 11px;
        color: rgba(0, 0, 0, 0.65);
    }
    .mob{
        color: #000;
        font-size: 12px;
    }
    .right{
        text-align: right;
    }
}

.loan-type-add{
    color: #2B8CFF;
    cursor: pointer;
}

.payout-input{
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 11px !important;
    padding: 10px;
    border: solid 1px #f1f1f1;
}

.custom-input {
    border-radius: 11px !important;
    padding: 8px;
    border: 1px solid #d9d9d9 !important;
    height: 39px !important;
    input {
      border: none;
      outline: none;
    }
  }
.custom-radio-business{
    width: 200px;
    .ant-radio-button-wrapper{
        display: block;
        float: left;
    }
}

.custom-radio-business{
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.custom-radio-business::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.search-result{
    .selected-item{
        margin: 23px 0px;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        // border: solid 1px #ccc;
        border: none !important;
        background-color: #C71C8E20;
        .ant-space-item{
            line-height: normal;
            .ant-typography{
                margin-bottom: 0px;
                line-height: normal;
            }
        }
    }
}

.step-not-valid{
    .step-button{
        background: #fff;
        border: #fff;
    }
}


