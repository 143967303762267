.history-logo {
  --bgColor: #273896;
  width: 40px;
  height: 40px;
  font-weight: normal;
  font-size: 20px;
  background-color: var(--bgColor);
  border-radius: 80%;
  display: grid;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.history-date {
  font-weight: 400;
  font-size: 11px;
  color: #a4a4a4;
}

.history-logo-header {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-top: 0.4rem;
}

.history-header-name {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.history-tag {
  --tagBgColor:#4285F430;
  background-color: var(--tagBgColor)!important;
  color: #000;
  border: none;
  margin-bottom: 10px;
}

.history-sub-details {
  font-weight: 300;
  margin-left: 7px;
}