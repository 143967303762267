.types-document {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.main-view {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 20px;
}

.loader-view {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: black;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

// .dynamic-radio{
//     --radioColor: #273896;
//     .ant-radio-checked .ant-radio-inner {
//         border-color: var(--radioColor) !important;
//     }
//     .ant-radio-inner:after {
//         background-color: var(--radioColor) !important;
//     }
// }