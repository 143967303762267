.lender-dec-tab{
    padding-bottom: 15px;
    .ant-radio-wrapper{
        margin-right: 0px;
        span{
            font-size: 12px;
            padding-left: 5px;
        }
    }
}

.lender-space{
    width: 100%;
}
.lender-collapse{
    background-color: #fff;
    border: solid 1px #f6f6f6;
    border-radius: 6px;
    box-shadow: 0px 1px 5px #e1e1e1;
    .ant-collapse-item{
        border-bottom: none;
        .ant-collapse-header{
            width: 100%;
            padding: 4px 8px;
            .ant-collapse-expand-icon{
                display: none;
            }
            .ant-collapse-header-text{
                width: 100%;
                h4{
                    font-size: 14px;
                    line-height: normal;
                    margin-bottom: 0px;
                }
                h6{
                    font-size: 13px;
                    margin-bottom: 0px;
                }
                .api-intergrated-text{
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    color: green;
                    line-height: 15px;
                }
            }
        }
        .ant-collapse-content-box{
            padding-top: 0px;
        }
    }

}