.logo-sidebar {
  text-align: center;
  border-bottom: none !important;
  margin: 10px 0px;
  img {
    width: 75%;
    min-height: 50px;
    max-height: 70px;
    object-fit: contain;
  }
}

.nav-panel {
  --bgColor: #27389633;
  --iconColor:#273896;
    width: 100%;
    padding-inline: 10px;
    padding-bottom: 20px;
    //background: rgb(43, 55, 145);
    background: white;
    .ant-menu.ant-menu-inline-collapsed {
      .ant-menu-item {
        padding-left: 24px !important;
      }
    }
    .ant-menu {
      //color: white !important;
      font-weight: 300;
      border-inline-end: none !important;
      background: none !important;
      .ant-menu-item,
      .ant-menu-item-group-title {
        padding:8px 8px 8px 8px;
        padding-left: 14px !important;
        color: black !important;
        font-weight: 300 !important;
        span {
          font-weight: 300 !important;
        }
      }
      .ant-menu-item-icon {
        rect,
        path,
        circle {
          stroke:var(--iconColor);
        }
      }
      .custome-fill {
        fill: var(--iconColor);
      }
      .stroke-fill {
        path {
          fill: var(--iconColor);
        }
      }
      .stroke-white {
        stroke: white !important;
        fill: var(--iconColor);
      }
      .ant-menu-item-active,
      .ant-menu-item-selected {
        //background-color: #4285f4 !important;
        background-color: var(--bgColor) !important;
        border-radius: 8px;
        color: black !important;
        .ant-menu-item-icon {
          rect,
          path,
          circle {
            stroke: var(--iconColor);
          }
        }
        .custome-fill {
          fill: white;
        }
        .stroke-fill {
          path {
            fill: white;
          }
        }
      }
    }
  }

.ant-menu-inline {
  .ant-menu-item:after,.ant-menu-item:after 
   {
    border-right: none;
   }
  }