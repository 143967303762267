.iconColors {
    --color: ;
    $color: var(--color);

    @if $color {
        path {
            fill: var(--color);
        }
    }


}