@import './Variables.scss';

.page-wrapper {
  padding: 20px;
}

.form-wrapper {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.has-error {
  .help-block {
    display: none;
  }
}

.alert,
.alert-danger {
  display: none;
}