.upload-document-wrapper{
    h5{
        font-size: 16px;
        margin-bottom: 20px;
    }
    .upload-btn{
        width: 206px;
        margin: auto;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        border-color:#fff !important;
        svg{
            margin-right: 10px;
        }
        &:hover{
            border-color:#fff;
        }
    }
    
    .doc-list{
        display: table;
        width: 100%;
        padding-bottom: 30px;
        word-break: break-all;
        ul{
            list-style: none;
            padding: 0px;
            margin: 0px;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 1px #ededed;
                margin-bottom: 10px;
                padding-bottom: 10px;
                .doc-details{
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 32px;
                        color: #273896;
                        margin-right: 5px;
                    }
                    span{
                        width: 170px;
                        font-size: 13px;
                        text-overflow: ellipsis;
                        overflow: hidden; 
                        white-space: nowrap;
                        .type{
                            font-size: 12px;
                        }
                    }
                }
                .doc-action{
                    span{

                    }
                    span.remove{
                        color: #E12121;
                        cursor: pointer;
                        svg{
                            margin-right: 8px;
                            position: relative;
                            top: 3px;
                        }
                    }
                }
            }
            li:last-child{
                border-bottom: none;
            }
        }
    }
}

.icon-border{
    --fileIconColor:#273896;
    width: 25px;
    height: 25px;
    path{
        stroke: var(--fileIconColor);
    }
}

.upload-document-modal{
    padding-bottom: 20px;
}

.custom-select{
    width: 100%;
}

.upload-btn-submit{
    width: 100%;
    margin-top: 20px;
    border-radius: 30px;
    height: 41px;
}

.upload-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    button{
        margin: 0px 5px;
    }
}
.upload-wrapper{
    
    .ant-upload-select-text{
        width: 100%;
        height: 76px;
        margin: 14px 0px;
        svg{
            margin-right: 10px;
        }
        span{
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            border: dotted 1px #ccc;
            cursor: pointer;
        }
    }
    .ant-upload-list{
        padding-bottom: 15px;
        .ant-upload-list-item{
            margin-bottom: 10px;
        }
    }
}

  