.lead-detail-box1 {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: auto;
  word-break: break-all;

  .heading {
    display: flex;
    border-bottom: solid 1px #ccc;
    padding: 6px 15px;
    justify-content: space-between;
    min-height: 46px;
    align-items: center;

    h5 {
      font-weight: 900;
      font-size: 17px;
      line-height: 22px;
      color: #000;
      margin-bottom: 0px;
    }

    button {
      font-size: 12px;
    }
  }

  .title {
    color: #9f9f9f;
    font-size: 13px;
  }

  .img-upload {
    margin-top: 90px;
    margin-left: -34px;

    svg {
      font-size: 25px;
      margin-left: 2px;
    }
  }

  .lead-details {
    padding: 10px 15px;

    .ant-radio-group {
      display: flex !important;
    }

    .source {
      font-weight: 400;
      font-size: 12px;
      color: #6a6767;
    }

    h5 {
      margin-bottom: 2px;
    }
  }

  .details-item {
    padding: 15px;

    textarea {
      width: 100%;
      border-radius: 8px;
    }

    .btn-space {
      display: flex;
      margin-top: 10px;
      justify-content: end;
    }
  }

  .profile-detail-document-box {
    height: 100%;
  }

  .tab-details {
    .ant-tabs {
      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          width: 100%;
        }
      }
      .ant-tabs-nav .ant-tabs-nav-operations {
        position: relative !important;
      }
    }
  }

  .ant-collapse {
    margin: 10px;
  }
}

.ant-radio-button-wrapper {
  .ant-radio-button {
    background-color: #ffffff;
    border: transparent;
  }
}

.label_verified {
  position: absolute;
  margin-left: 10px;
  color: #20a74e;
  border: 1px solid #20a74e;
  font-size: 11px;
  padding: 0px 5px;
  border-radius: 5px;

  svg {
    margin-right: 5px;
    margin-bottom: 3px;
  }
}

.label_verficiation_pending {
  color: #e12121;
  border: 1px solid #e12121;
}

.btn-verify {
  font-size: 13px;
  height: 25px;
  border: 1px solid red;
  border-radius: 7px;
  padding-block: 1px;
  padding-inline: 10px;
  color: red;
}

.label-verified {
  position: absolute;
  top: -23px;
  font-size: 13px !important;
  right: 0;
  color: #20a74e;
  padding: 5px 0px;

  svg {
    font-size: 12.5px !important;
    font-weight: bolder;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 13px !important;
    height: 15px !important;
  }
}

.label-verified-rejected {
  font-size: 11px !important;
  color: #e12121;
  border: none;
  padding: 5px 0px;

  svg {
    font-size: 11px !important;
    margin-right: 2px;
    width: 10px !important;
    height: 10px !important;
    margin-bottom: 0px;
  }
}

.card-container {
  .ant-tabs-card .ant-tabs-nav-list {
    .ant-tabs-tab {
      background: transparent;
      border-color: transparent;
    }
  }

  .ant-tabs-nav {
    background-color: rgb(232, 235, 245);
    border-radius: 7px;
  }

  .ant-tabs-tab-btn {
    font-size: 15px !important;
    font-weight: 300;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
  }

  .ant-tabs-card .ant-tabs-nav-list {
    .ant-tabs-tab-active {
      border-radius: 5px;
      background: #fff;
      border-color: #fff;
    }
  }

  .ant-tabs-card {
    .ant-tabs-nav::before {
      display: none;
    }
  }
}

.ant-tabs-nav .card-container {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #000;
  }
}

#borderimg {
  --color: #C71C8E;
  border-radius: 100%;
  border: 0px solid transparent;
  padding: 2px;
  background-image: linear-gradient(var(--color), var(--color));
  object-fit: cover;
}

.input-field {
  padding: 1px 3px;
  background: #ffffff;
  box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
  border-radius: 12px;
  border: solid 1px #f7f7f7;
  margin-bottom: 10px;

  .custom-input {
    border-radius: 0px;
    padding: 2px;
    padding-left: 0px;
    border: none !important;
  }
}

.container {
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.profile-custom-row {
  display: flex;
  justify-content: center;
  padding: 10px;

  .gap {
    gap: 4px !important;
  }
}

.btn-save-cancel {
  width: 100%;
  margin-top: 2%;
  border-radius: 8px;
}

.remove-border {
  border: none;
  :hover {
    border: 0px transparent !important;
  }
}

.profilIcons {
  margin-top: 12px;
  text-align: center;
}

.tab-dot {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #e03636c1;
  border-radius: 10px;
  height: 10px;
  width: 10px;
}

.user-icon {
  --appPrimary: #273896;
  circle,
  path {
    stroke: var(--appPrimary);
  }
}
.ant-collapse-content-box{
  height: 100% !important;
  overflow: scroll !important;
}