// ul {
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
//     align-content: flex-start;
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     height: 30em;
//   }

//   li {
//     background: gray;
//     width: 5em;
//     height: 5em;
//     margin: .5em;
//   }
.filter-custom-select {
    .ant-select-selector {
        height: 42px !important;
        border-radius: 10px !important;
    }
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner{
    background-color: #20A74E;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    background-color: #20A74E;
}
.input-search-box{
    width: 100%;
    border-radius: 12px;
    height: 45px;
}