@import '../../shared/style/Variables.scss';

.eligible-logo {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.eligible-msg {
    display: flex;
    justify-content: center;
    color: $primary-color;
    font-size: large;
    margin: 15px;
}

.amount {
    display: flex;
    justify-content: center;
    font-size: larger;
    color: $white;
    padding-top: 15px;
    vertical-align: middle;
}

.loan-title-type {
    display: flex;
    justify-content: center;
    color: $font-color-lightgrey;
    vertical-align: middle;
}

.divider-clac {
    border: 1px dashed $font-color-lightgrey;
}

.ant-divider-horizontal {
    margin: 12px 0px !important;
}

.back-header {
    background-color: $font-back-ground;
    margin-bottom: 20px;
}

.reject-mag {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    font-size: larger;
    color: $font-reject-msg;
}

.eligible-value {
    display: flex;
    justify-content: center;
    margin-left: 100px;
    margin-top: 10px;
}

.reject-logo {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.calculate-button {
    display: flex;
    justify-content: center;
    margin-block: 30px;
}