.lender-privacy-policy-header {
  display: flex;
  justify-content: space-between;
  background: #ebeef359;
  padding-inline: 20px;
  padding-block: 5px;
  align-items: center;
}

.lender-privacy-policy-main-div {
  padding: 5px;
  padding-inline: 20px;
  border-bottom: 1px solid #dfe0eb;
}

.privacy-policy-label {
  font-size: 10px !important;
  &.custome-lable {
    min-height: 13px;
    padding-top: 8px;
  }
  .ant-form-item-label {
    label {
      font-size: 10px !important;
      height: 23.55px;
      padding-top: 6px;
    }
  }
}

.privacy-lenderName {
  font-size: 12px;
  word-break: break-word;
  width: 83%;
  max-width: 100%;
}

.policy-number {
  font-size: 10px;
  word-break: break-word;
  width: 90%;
  max-width: 100%;
}

.fields-text {
  font-size: 10px !important;
}

.privacy-policy-details {
  display: grid;
  color: rgba(60, 60, 60);
  width: 100%;
  .item {
    font-size: 12px;
  }
}

.privacy-policy-form-item {
  .ant-form-item-label {
    label {
      font-size: 12px !important;
    }
  }
  .ant-form-item-explain-error {
    font-size: 12px !important;
  }
}
