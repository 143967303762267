.faq-panel {
  // .ant-collapse-header {
  //   background-color: white !important;
  // }

  .ant-collapse-item {
    border-radius: 10px !important;
    box-shadow: 0px 0px 11px 0px rgba(33, 33, 33, .2) !important;
    margin-bottom: 10px;
  }

  .ant-collapse-content {
    background-color: #E5E8F5 !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    .ant-collapse-content-box {
      //padding-bottom: 0px !important;
      padding: 15px 10px 8px !important;
    }
  }

  .ant-collapse-expand-icon {
    display: "flex" !important;
    justify-content: "end" !important;
    // margin-left: 100px !important;
  }
}

.underline {
  height: 1px;
}

.content {
  min-width: 270px;
  max-width: 52%;
}

.about_name {
  margin-bottom: -1em;
}

.text {
  float: right;
  margin-top: -12px;
}

/*.about_btn {
  margin-top: 30px;
  width: 40%;
  margin-left: 60px;
}*/

/*.copy_btn {
  height: 45px;
  width: 150px !important;
  border-radius: 20px;

  svg {
    margin-right: 10px;
    font-size: 14px;
  }
}*/

/*.share_btn {
  height: 45px;
  width: 150px !important;
  border-radius: 20px;

  svg {
    margin-right: 10px;
    font-size: 14px;
  }
}*/

.btn-box {
  --bgColor:#273896;
  
  .help-tab-box {
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: white !important;
    }

    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
      border-bottom-color: #fff;
      background: var(--bgColor) !important;
      color: white;
      border-radius: 10px 9px 0px 0px !important;
    }

    .ant-tabs-tab {
      background: #F8F9FF !important;
      border-radius: 10px 9px 0px 0px !important;
    }
  }
}

.about-content {


  .about-content-details {
    .ant-card-body {
      padding: 3px 20px;
    }
  }

  .detail-label {
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .detail-text {
    text-align: right;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .detail-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
}