.custom-radio-new {
    --bgColor: #C71C8E;
    // .ant-space-align-center {
    //   .ant-space-item {
    //     .ant-radio-button-wrapper-checked {
    //       color: #fff;
    //       border-color: #C71C8E !important;
    //     }
   

    .ant-radio-button-wrapper {
        box-shadow: none;
        border-radius: 30px;
        border-width: 1px;
        &:hover {
            background-color: var(--bgColor);
            border-color: var(--bgColor) !important;
        }

        &:before {
            display: none;
        }
    }

    .ant-radio-button-checked,
    .ant-radio-button-wrapper-checked {
        background-color: var(--bgColor);
        border-radius: 30px;
        color: #fff !important;
        border: none;
    }

    //   }
    // }
}