@import "shared/style/Variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-app-tab .ant-tabs-nav {
  margin-bottom: 0px !important;

}

.custom-app-card {
  border-top-left-radius: 0px !important;
}

.ant-upload.ant-upload-drag {
  margin-top: 10px;
}

/*Common Classes*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.full-width {
  width: 100%;
}

.no-pd {
  padding: 0;
}

.pd-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.no-mr {
  margin: 0;
}

.m-i-5 {
  margin-inline: 5px;
}

/*Ant Design*/
.ant-form-item.oneflo-bottom-space {
  margin-bottom: 12px;
}

.ant-form-item.oneflo-bottom-space,
.ant-form-item.oneflo {

  .ant-form-item-label {
    margin-bottom: 0px;

    // label {
    //   color: black;
    //   height: 30px;
    // }
  }

  .ant-input-affix-wrapper {
    border-radius: 10px;
  }

  input.ant-input {
    padding: 8px;
    border-radius: 10px;
    // border: 1px solid #adadad;
  }

  .ant-input-number,
  .ant-select-selector {
    border-radius: 10px;
    padding-block: 4px;
  }

  .ant-form-item-with-help,
  .ant-form-item-explain {
    margin-bottom: 10px;
  }
}

.ant-form-item {

  &.label-pt {
    .ant-form-item-label {
      padding-top: 8px;
    }
  }

  .oneflo-full-btn {
    width: 100%;
    margin-top: 8px;
    //height: 50px;
    padding: 8px;
    border-radius: 10px;
  }

  .oneflo-full-default-btn {
    width: 100%;
    margin-top: 8px;
    //height: 50px;
    padding: 8px;
    border-radius: 10px;
    background-color: #fff;
    border-color: #c71c8e;
    color: #c71c8e;

    &:hover {
      background-color: #c71c8e;
      border-color: #c71c8e;
      color: #fff;
    }
  }

  .ant-radio-wrapper {
    .ant-radio+span {
      position: relative;
      top: -5px;
    }

    .ant-radio-inner,
    .ant-radio-inner:hover {
      background-color: #fff !important;
      border-color: $app-pink !important;
      height: 20px;
      width: 20px;

      &:after {
        // top: 43%;
        left: 46%;
        height: 17px;
        width: 17px;
        background-color: $app-pink !important;
      }
    }
  }

  .dynamic-radio {
    --borderColor: #c71c8e;

    .ant-radio-inner {
      border: 1px solid var(--borderColor) !important;
    }

    .ant-radio-inner:after {
      background: var(--borderColor) !important;
      border: 1px solid var(--borderColor) !important;
    }

    .ant-radio-wrapper.lg .ant-radio-inner,
    .ant-radio-wrapper.lg .ant-radio-inner.lg:hover {
      background-color: #fff !important;
      border: 2px solid var(--borderColor) !important;
      height: 30px;
      width: 30px;

      &:after {
        top: 23%;
        left: 24%;
        height: 30px;
        width: 30px;
        background-color: var(--borderColor) !important;
      }
    }
  }

}

.ant-table table {
  border: none !important;
  border-bottom: none;
}

thead[class*='ant-table-thead'] th {
  // opacity: 0.7 !important;
  font-size: smaller;
}

.date-range {
  border-radius: 10px !important;
  height: 45px;
  box-shadow: 0.5px 0.5px 10px 1px #b0b0b09e !important;

  svg {
    font-size: 20px;
  }
}

.data-filter-bar {
  //padding: 10px 0px;
  width: auto;
  //gap: 5px;
  margin-bottom: 10px;

  .search-box {
    width: 100%;
    border-radius: 12px;
    height: 45px;
  }

  .filter-btn {
    width: 45px;
    height: 46px;
    border-radius: 12px;
    padding: 10px 12px;
    background-color: #fff;
    //color: #2e43b2;
    border-color: #fff;
    // margin-right: 15%;

    svg {
      font-size: 18px;
      vertical-align: middle;
    }
  }

  .refresh-btn {
    width: 45px;
    height: 45px;
    border-radius: 12px;
    padding: 10px 12px;

    svg {
      font-size: 18px;
      vertical-align: middle;
    }
  }

  .add-btn,
  .bulk-lead-btn,
  .share-link-btn {
    // width: 100%;
    height: 45px;
    border-radius: 12px;
    padding: 10px 12px;

    svg {
      font-size: 18px;
      vertical-align: middle;
    }
  }
}

.export-btn,
.copy-btn {
  &.full-width {
    width: 100%;
  }

  &.w-100 {
    width: 100px !important;
  }

  height: 45px !important;
  border-radius: 12px !important;
  padding: 10px 12px !important;

  svg {
    font-size: 18px;
    vertical-align: middle;
  }
}

.refresh-button {
  height: 41px;
  width: 37px;
  border-radius: 10px;
  background-color: #fff !important;
  border: none !important;
  margin-right: 20px;

  svg {
    font-size: 18px;
  }
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background-color: #e9e9e9;
  // border-color: #2e43b2;
  color: black;
}