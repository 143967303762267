
.upload {
    width: 90%;
    min-height: 100px;
    padding-top: 6%;
    padding: 1 10px;
    background: rgba(39, 56, 150, 0.13);
    border: 1px dashed #273896;
    border-radius: 12px;
    margin: 0 20px 20px 0;
    color: #273896;
   
}

.noticeText {
    text-size-adjust: auto;
}

.searchBox {
    border-radius: 30px;

}
.div {
    display: flex
}
.link {
    --color : #1890ff;
    color: var(--color) !important;
    font-size: inherit;
 }
 
 .upload-wrapper1{
    
    .ant-upload-select-text{
        width: 100%;
        height: 76px;
        margin: 14px 0px;
        svg{
            margin-right: 10px;
        }
        span{
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            border: dotted 1px #ccc;
            cursor: pointer;
        }
    }
    .ant-upload-list{
        // padding-bottom: 15px;
        .ant-upload-list-item{
            margin-bottom: 10px;
        }
    }
}
.discrption{
    width: 90vw;
    max-width: 500px;
}

// .title {
//     background: #ffffff;
//     box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
//     border-radius: 12px;
//     width: 100%;
//     height: 52px;
// }
// .description {
//     background: #ffffff;
//     box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
//     border-radius: 12px;
//     width: 100%;
//     line-height: 40px;
//     padding-bottom: 300px;
// }
// .submit {
//     /* width: 261.94px; */
//     width: 100%;
//     height: 55px;
//     background: #273896;
//     border-radius: 10px;
//     color: #fff;
//     margin-left: 5%;
// }

// .cancel {
//     box-sizing: border-box;
//     /* width: 174.41px; */
//     width: 100%;
//     height: 55px;
//     border: 1px solid #c71c8e;
//     border-radius: 10px;
//     margin-right: 20px;
//     color: #c71c8e;
// }
