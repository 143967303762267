.authentication-main-view {
  background: #283897;
  width: 100%;
  //height: auto;
  min-height: 100vh;
  padding: 0 4.5% 0px;
  // background-image: url("../../assets/images/backgroundimage.svg");
  display: flex
;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  .header-logo-details {
    color: white;
    // padding-top: 140px;

    .page-header {
      font-size: 30px;
      color: white;
      margin-bottom: 13px;
      font-weight: 300;
    }

    .logo-details {
      width: 100%;
    }

    .header-content {
      font-size: 11px;
    }
  }

  .flying-plane {
    padding-top: 10px;
  }

  .authentication-container {
    padding-top: 2%;
    margin-bottom: 10px;
    width: 100%;
    padding-bottom: 2%;

    .authentication-box {
      min-height: 400px;
      padding: 3% 18px 3%;
      border-radius: 30px;
      background: white;
      // height: 500px;
      width: 100%;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);

      .welcome-to-oneflo {
        color: black;
        font-size: 20px;

        .oneflo {
          text-transform: uppercase;
          color: #c71c8e;
          font-size: 21px;
          padding-left: 5px;
        }
      }

      .authentication-header {
        font-size: 40px;
        color: black;
        margin-bottom: 5px;
      }

      .authentication-header-small {
        font-size: 28px;
        color: black;
        margin-bottom: 10px;
      }

      .authentication-header-small-no-bottom-space {
        font-size: 28px;
        color: black;
        margin-bottom: 0px;
      }

      .authentication-header-addtional-details {
        font-size: 14px;
        margin-bottom: 0px;
      }

      .authentication-form {
        .sign-up-type-divider {
          background-color: rgb(39, 56, 150);
          height: 1.3px;
          margin: 0 -40px 22px;
        }

        .forgot-password-link,
        .sign-in-link,
        .sign-up-link {
          color: #c71c8e;
          text-decoration: none;
        }

        .sign-in-link,
        .sign-up-link {
          font-size: 17px;
        }

        .already-a-user,
        .no-account {
          color: #8d8d8d;
          font-size: 15px;
          margin: 0px;
        }
      }
    }
  }
}

.sign-up-input {
  margin-top: 20px;
}

.already-a-user-main {
  margin-top: 20px;
  .already-a-user {
    color: #8d8d8d;
    font-size: 15px;
    margin: 0px;
    .sign-in-link {
      color: #c71c8e;
      text-decoration: none;
      font-size: 17px;
    }
  }
}

.passwordPolicy{
  margin: 5px;
  color:rgba(147, 146, 146, 1)
}

.imagePasswrodPolicy{
  margin-right: 10px;
}
.authView{
  background: #283897;
  height: 100%;
}
.oneflo-full-btn{
  margin-top: 0px !important;
}
.oneflo-bottom-space{
  margin-bottom: 0px !important;
}
.crystal1{
  position: absolute;
}
.ring{
  position: absolute;
  margin-top: 11%;
}
.coin1{
  position: absolute;
    margin-left: 18%;
}
.coins2{
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.innovation1{
  position: absolute;
  margin-left: 54%;
  width: 120px;
}
.setting1{
  position: absolute;
    bottom: 5px;
    margin-left: 39%;
    width: 200px;
}
.moneysign{
  position: absolute;
  width: 118px;
  height: 134px;
  margin-left: 46%;
  margin-top: 14%;
}
.hierarchy1{
  position: absolute;
  bottom: 20px;
  margin-left: 67%;
  width: 125px;
}
.crystal3{
  position: absolute;
  right: 0px;
  margin-top: 9%;
  width: 177px;
  height: 177px;
}
.crystal2{
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 177px;
  height: 177px;
}

.imageView{
  background: #283897;
  //height: auto;
  min-height: 100vh;
  position: absolute;
  width: 100%;
}
.brainRow{
  min-width: 85vw;
}

 .g-recaptcha {margin: 0 auto;display: table;}

 @media screen and (max-width: 768px) {

  .authentication-container {
    padding-bottom: 0px;
    padding-top: 55px !important;
  }
  .header-logo-details{
    padding-top: 50px !important;
  }
  .authentication-main-view{
    height: auto;
  }
  .ring{
    display: none;
  }
  .coin1{
    display: none;
  }
  .coins2{
    display: none;
  }
  .innovation1{
    display: none;
  }
  .setting1{
    display: none;
  }
  .moneysign{
    display: none;
  }
  .hierarchy1{
    display: none;
  }
  .crystal3{
    display: none;
  }
  .crystal2{
    display: none;
  }
}
@media only screen 
  and (min-width: 769px) 
  and (max-width: 990px) { 

    .header-logo-details{
      width: 45%;
      max-width: 45%;
      margin-right: 7%;
    }
    .authentication-container{
      width: 45%;
      max-width: 45%;
      
    }
  
} 

 
